import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Loadable from '@loadable/component';
import {
  deriveStationDockedCraftCount,
  deriveStationPortCount,
  Station,
  StationPass,
  StationPort
} from 'superclient';
import { useQueryBool, useQueryString } from '../state/use-query';
import MetaModule from '../components/core/meta-module';
import StationTrafficView from '../components/stations/station-traffic-view/station-traffic-view';
import StationPortList from '../components/stations/station-port-list/station-port-list';
import StationCrew from '../components/stations/station-crew/station-crew';
import StationCraftPreview from '../components/stations/station-craft-preview/station-craft-preview';
import StationMobileTabView from '../components/stations/station-mobile-tab-view/station-mobile-tab-view';
import Dynamic from '../components/core/dynamic';
import StationSelector from '../components/stations/station-selector/station-selector';
import StationTimetable from '../components/stations/station-timetable/station-timetable';
import { Superclient } from '../clients/superclient';
import StationSightings from '../components/stations/station-sightings/station-sightings';
import StationSightingModal from '../components/stations/station-sighting-modal/station-sighting-modal';
import StationTimezoneSelector from '../components/stations/station-selector/station-timezone-selector';

const StationLocation = Loadable(() =>
  import('../components/stations/station-location/station-location')
);
const StationTitleBar = Loadable(() =>
  import('../components/stations/station-title-bar/station-title-bar')
);

const tabNames = ['ports', 'crew', 'location', 'stream', 'science'];

const TemplateStations: FC<{
  pageContext: { stations: Station[] };
}> = ({ pageContext }) => {
  const [highlightedPort, setHighlightedPort] = useState<string>();
  const [selectedPort, setSelectedPort] = useState<string>();
  const [showTopView, setShowTopView] = useQueryBool('top', false);
  const [selectedTab, setSelectedTab] = useQueryString('tab', tabNames[0]);
  const [previewId] = useQueryString('id', undefined);
  const [selectedStationSlug, setSelectedStationSlug] = useQueryString(
    'station',
    'iss'
  );
  const [showUTCTime, setShowUTCTime] = useQueryBool('utc', false);
  const [selectedPass, setSelectedPass] = useState<StationPass>();

  const [stations, setStations] = useState(pageContext.stations);
  const [previewStation, setPreviewStation] = useState<Station>();
  const station = useMemo(() => {
    return (
      previewStation || stations.find((s) => s.slug.current === selectedStationSlug)
    );
  }, [selectedStationSlug, stations, previewStation]);
  useEffect(() => {
    Superclient.getStations().then(setStations);
  }, []);
  useEffect(() => {
    if (!previewId || previewId.length === 0) {
      setPreviewStation(undefined);
      return;
    }

    Superclient.getStation(previewId).then((_previewStation) => {
      if (Object.keys(_previewStation).length === 0) return;
      setPreviewStation(_previewStation);
    });
  }, [previewId]);

  const selectedTabIndex = useMemo(() => tabNames.indexOf(selectedTab), [
    selectedTab
  ]);

  const dockedCraftCount = useMemo(() => deriveStationDockedCraftCount(station), [
    station
  ]);
  const totalPorts = useMemo(() => deriveStationPortCount(station), [station]);

  useEffect(() => {
    if (selectedPort !== highlightedPort) {
      setHighlightedPort(undefined);
    }
  }, [selectedPort]);

  const onSetSelectedTab = useCallback(
    (i: number) => setSelectedTab(tabNames[i]),
    []
  );

  let selectedCraftPort: StationPort | undefined;
  station?.portGroups.forEach((portGroup) => {
    portGroup.ports.forEach((p) => {
      if (p._key === selectedPort) {
        selectedCraftPort = p;
      }
    });
  });

  const mobileTabs = useMemo(
    () => [
      {
        title: 'Sightings',
        view: (
          <StationSightings
            isMobile
            station={station}
            showUTCTime={showUTCTime}
            onSelectPass={setSelectedPass}
          />
        )
      },
      {
        title: 'Ports',
        view: (
          <StationPortList
            isMobile
            portGroups={station.portGroups}
            highlightedPort={highlightedPort}
            onPortHighlight={(portName) => setHighlightedPort(portName)}
            selectedPort={selectedPort}
            onSelectedPort={(index) => setSelectedPort(index)}
          />
        )
      },
      {
        title: 'Crew',
        view: (
          <StationCrew
            isMobile
            crew={(station.crew || []).map((c) => c.astronaut)}
          />
        )
      },
      {
        title: 'Location',
        view: <StationLocation isMobile station={station} />
      }
    ],
    [station, highlightedPort, selectedPort]
  );

  return (
    <div className="iss_traffic_index">
      <MetaModule
        title="Stations"
        path="/stations"
        keywords="ISS, Tiangong, Traffic, Rockets, Docking"
        description="Keep track of every spaceship that visits the International Space Station and Tiangong with this real-time visual dashboard."
        customImage={{
          src: 'https://www.supercluster.com/iss-tracker-share.png',
          width: 2363,
          height: 1024
        }}
      />
      <StationTitleBar
        stationName={station.name}
        totalPorts={totalPorts}
        portsUsed={dockedCraftCount}
        showTopView={showTopView}
        setShowTopView={setShowTopView}
      />
      <div className="iss_traffic_index__content" style={{ minWidth: '100%' }}>
        <Dynamic
          className="iss_traffic_index__iss_container"
          dependencies={[station]}
        >
          <StationTrafficView
            station={station}
            showTopView={showTopView}
            highlightedPort={highlightedPort}
            onPortHighlight={setHighlightedPort}
            selectedPort={selectedPort}
            onSelectedPort={setSelectedPort}
          />
          {!previewStation && (
            <div className="iss_traffic_index__options">
              <StationSelector
                selectedStationSlug={selectedStationSlug}
                onSelectStation={setSelectedStationSlug}
              />
              <StationTimezoneSelector
                showUTCTime={showUTCTime}
                onSetShowUTCTime={setShowUTCTime}
              />
            </div>
          )}

          <div className="iss_traffic_index__left_bar">
            <StationPortList
              isMobile={false}
              portGroups={station.portGroups}
              highlightedPort={highlightedPort}
              onPortHighlight={(index) => setHighlightedPort(index)}
              selectedPort={selectedPort}
              onSelectedPort={(index) => setSelectedPort(index)}
            />
            <div className="iss_traffic_index__bar_bottom_content">
              <StationCrew
                isMobile={false}
                crew={(station.crew || []).map((c) => c.astronaut)}
              />
            </div>
          </div>
          <div className="iss_traffic_index__right_bar">
            <StationSightings
              isMobile={false}
              station={station}
              showUTCTime={showUTCTime}
              onSelectPass={setSelectedPass}
            />
            <div className="iss_traffic_index__bar_bottom_content">
              <StationLocation station={station} />
            </div>
          </div>
          {selectedPort === undefined && (
            <StationMobileTabView
              selectedTab={selectedTabIndex}
              setSelectedTab={onSetSelectedTab}
              tabs={mobileTabs}
            />
          )}
        </Dynamic>
        {selectedCraftPort && (
          <div className="iss_traffic_index__mobile_content_overlay">
            <StationCraftPreview
              isMobile
              portName={selectedCraftPort?.portName}
              portNumber={selectedCraftPort.portNumber}
              craft={selectedCraftPort.craft}
              craftUniqueName={selectedCraftPort.craftUniqueName}
              craftArrivalDate={selectedCraftPort.arrivalDate}
              craftOverrideArrivalDate={selectedCraftPort.overrideArrivalDate}
              craftDepartureDate={selectedCraftPort.departureDate}
              craftOverrideDepartureDate={selectedCraftPort?.overrideDepartureDate}
              onClose={() => {
                setSelectedPort(undefined);
                setHighlightedPort(undefined);
              }}
            />
          </div>
        )}

        {selectedPass && (
          <StationSightingModal
            station={station}
            pass={selectedPass}
            showUTCTime={showUTCTime}
            onClose={() => setSelectedPass(undefined)}
          />
        )}
      </div>
    </div>
  );
};

export default TemplateStations;
