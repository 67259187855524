import React, { FC } from 'react';
import * as styles from './station-selector.module.scss';

const stations = [
  {
    title: 'ISS',
    slug: 'iss'
  },
  {
    title: 'Tiangong',
    slug: 'tss'
  }
];

const StationSelector: FC<{
  selectedStationSlug: string;
  onSelectStation: (slug: string) => void;
}> = ({ selectedStationSlug, onSelectStation }) => {
  return (
    <ul className={styles.station_selector}>
      {stations.map((station) => (
        <li
          key={station.slug}
          className="bau"
          data-selected={station.slug === selectedStationSlug}
          onClick={() => onSelectStation(station.slug)}
        >
          {station.title}
        </li>
      ))}
    </ul>
  );
};

export default StationSelector;
