import React, { FC } from 'react';
import cx from 'classnames';
import * as styles from './station-mobile-tab-view.module.scss';

const StationMobileTabView: FC<{
  tabs: { title: string; view: React.ReactElement }[];
  selectedTab: number;
  setSelectedTab: (value: number) => void;
}> = ({ tabs, selectedTab, setSelectedTab }) => {
  return (
    <div className={styles.station_mobile_tab_view}>
      <div
        className={`${styles.station_mobile_tab_view__tabs} f fr jcb ovfxs no-scrollbar`}
      >
        {tabs.map((tab, index) => (
          <div
            key={`iss-tab-${index}`}
            className={cx('pb05 pt075 px075 akkura caps clickable mr025', {
              bcw: selectedTab === index,
              cb: selectedTab === index
            })}
            onClick={() => setSelectedTab(index)}
          >
            <h6 className="caps pb0 mb025 mt0">{tab.title}</h6>
          </div>
        ))}
      </div>
      <div className={`${styles.station_mobile_tab_view__margin} f1 rel`}>
        <div className="abs x y ovfys f light-scroll-bar">
          {tabs[selectedTab].view}
        </div>
      </div>
    </div>
  );
};

export default StationMobileTabView;
