// extracted by mini-css-extract-plugin
export var station_sighting_modal = "station-sighting-modal-module--station_sighting_modal--3PvgR";
export var station_sighting_modal__header = "station-sighting-modal-module--station_sighting_modal__header--1z7UQ";
export var station_sighting_modal__compass_container = "station-sighting-modal-module--station_sighting_modal__compass_container--s_pqp";
export var station_sighting_modal__compass = "station-sighting-modal-module--station_sighting_modal__compass--2Y4pr";
export var station_sighting_modal__compass_dir = "station-sighting-modal-module--station_sighting_modal__compass_dir--1i84P";
export var station_sighting_modal__compass_arrow = "station-sighting-modal-module--station_sighting_modal__compass_arrow--2zdbS";
export var station_sighting_modal__altitude = "station-sighting-modal-module--station_sighting_modal__altitude--1ImSx";
export var station_sighting_modal__altitude_bar = "station-sighting-modal-module--station_sighting_modal__altitude_bar--2ZNGM";
export var station_sighting_modal__brightness = "station-sighting-modal-module--station_sighting_modal__brightness--1oPzF";
export var station_sighting_modal__brightness_bar = "station-sighting-modal-module--station_sighting_modal__brightness_bar--3xO19";
export var station_sighting_modal__brightness_circle = "station-sighting-modal-module--station_sighting_modal__brightness_circle--1c3Qo";