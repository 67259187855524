import React, { FC, useEffect, useMemo } from 'react';

const Dynamic: FC<{ className: string; dependencies?: any[] }> = ({
  children,
  dependencies,
  ...delegated
}) => {
  const [hasMounted, setHasMounted] = React.useState(false);
  const allDependenciesMet = useMemo(
    () => !dependencies || dependencies.every((d) => !!d),
    dependencies || []
  );

  useEffect(() => {
    setHasMounted(true);
    // setTimeout(() =>
    // setHasMounted(true), 1000);
  }, []);

  if (!hasMounted || !allDependenciesMet) {
    return null;
  }
  return <div {...delegated}>{children}</div>;
};

export default Dynamic;
