import React, { FC, useEffect, useState } from 'react';
import {
  Station,
  StationPass,
  degToCardinal,
  generatePassDescription
} from 'superclient';
import * as styles from './station-sighting-modal.module.scss';

export const mapNumber = (
  value: number,
  inputMin: number,
  inputMax: number,
  outputMin: number,
  outputMax: number
) => {
  return (
    ((value - inputMin) / (inputMax - inputMin)) * (outputMax - outputMin) +
    outputMin
  );
};

const StationSightingModal: FC<{
  station: Station;
  pass: StationPass;
  showUTCTime: boolean;
  onClose: () => void;
}> = ({ station, pass, showUTCTime, onClose }) => {
  const [visible, setVisible] = useState(false);
  const [passDescription, setPassDescription] = useState<{
    headline: string;
    detail: string;
  }>();

  useEffect(() => {
    const updateDescription = () => {
      setPassDescription(pass ? generatePassDescription(station, pass) : undefined);
    };
    updateDescription();

    const interval = setInterval(() => {
      updateDescription();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [pass, station]);

  useEffect(() => setVisible(true), []);

  const closeModal = () => {
    setVisible(false);
    setTimeout(() => {
      onClose();
    }, 150);
  };

  return (
    <div className={styles.station_sighting_modal} data-visible={visible}>
      <div className="dark-scrollbar f fc">
        <div className="f fr jcs">
          <div className={styles.station_sighting_modal__header}>
            <div className="bau bold">{passDescription?.headline}</div>
            <div className="small mt1">{passDescription?.detail}</div>
          </div>
          <button onClick={closeModal} className="ass pt025 pr0 clickable">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="15"
                cy="15"
                r="14.5"
                transform="rotate(-180 15 15)"
                stroke="white"
              />
              <line
                x1="10.5957"
                y1="10.4038"
                x2="19.7881"
                y2="19.5962"
                stroke="white"
              />
              <line
                x1="10.5966"
                y1="19.5962"
                x2="19.789"
                y2="10.4038"
                stroke="white"
              />
            </svg>
          </button>
        </div>

        <div
          className={`${styles.station_sighting_modal__compass_container} f1 ovfh f aic jcc`}
          style={{ padding: '14px' }}
        >
          <div className={styles.station_sighting_modal__compass}>
            <div>
              {['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'].map(
                (direction, index) => {
                  return (
                    <div
                      key={direction}
                      className={`${styles.station_sighting_modal__compass_dir} akkura xsmall`}
                      style={{
                        transform: `rotate(${index * (360 / 8)}deg)`
                      }}
                    >
                      <span>{direction}</span>
                      <div />
                    </div>
                  );
                }
              )}
              <div
                className={styles.station_sighting_modal__compass_arrow}
                style={{
                  transform: `rotate(${pass?.riseAzimuth * 1.2}deg)`
                }}
              >
                <div className="akkura caps xsmall">Start</div>
                <div />
                <div />
              </div>
              <div
                className={styles.station_sighting_modal__compass_arrow}
                style={{
                  transform: `rotate(${pass?.setAzimuth}deg)`
                }}
              >
                <div className="akkura caps xsmall">End</div>
                <div />
                <div />
              </div>
            </div>
          </div>
        </div>

        <div className="f fr x">
          <div className="f1">
            <div className="x ac caps akkura small">Altitude</div>
            <div className={styles.station_sighting_modal__altitude}>
              <div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 115 113"
                    fill="none"
                  >
                    <g stroke="#fff" strokeMiterlimit={10} clipPath="url(#a)">
                      <path
                        fill="#fff"
                        d="M7.69 91.62a2.72 2.72 0 1 0 0-5.44 2.72 2.72 0 0 0 0 5.44Z"
                      />
                      <path d="M7.69 91.62v10.87M0 94.34h7.69M15.38 94.34H7.69M14.98 110.18l-7.29-7.69M.4 110.18l7.29-7.69" />
                      <path
                        strokeDasharray="4 4"
                        d="M21.28 88.9H115M21.08 86.54 115 69.98M20.46 84.25 115 49.84M19.46 82.11 115 26.95M18.1 80.16 114.03-.33M16.43 78.49 82.56-.33M14.49 77.13 59.21-.33M12.34 76.13 40.17-.33M10.05 75.52 23.42-.33M7.69 75.31V-.33"
                      />
                    </g>
                    <defs>
                      <clipPath id="a">
                        <path fill="#fff" d="M0 0h115v113H0z" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div
                    className={styles.station_sighting_modal__altitude_bar}
                    style={{ transform: `rotate(${-pass?.peakAlt}deg)` }}
                  >
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="f1">
            <div className="x ac caps akkura small">Brightness</div>
            <div className={styles.station_sighting_modal__brightness}>
              <div>
                {Array.from({ length: 4 }).map((_, index) => {
                  return (
                    <div
                      key={index}
                      className={styles.station_sighting_modal__brightness_bar}
                      style={{
                        transform: `rotate(${index * (360 / 8)}deg)`,
                        height:
                          20 +
                          mapNumber(pass?.brightness || 0, 0, -5, 0, 1) * 60 +
                          '%'
                      }}
                    />
                  );
                })}
                <div className={styles.station_sighting_modal__brightness_circle}>
                  <div>
                    <div className="akkura xsmall caps">
                      {pass.brightness.toFixed(1)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="my2 ovfxs">
            {/* HTML Table with columns for time, direction, altitude and rows for start, max, and end */}
            <table className="dark-scrollbar">
              <thead>
                <tr>
                  <th></th>
                  <th className="akkura xsmall caps">Time</th>
                  <th className="akkura xsmall caps">Direction</th>
                  <th className="akkura xsmall caps">Altitude</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="akkura xsmall caps">Start</td>
                  <td className="akkura small">
                    {showUTCTime
                      ? pass?.riseTime.toUTC().toFormat('h:mm:ss a')
                      : pass?.riseTime.toFormat('h:mm:ss a')}
                  </td>
                  <td className="akkura small">
                    {degToCardinal(pass?.riseAzimuth || 0)}
                  </td>
                  <td className="akkura small">{Math.floor(pass?.riseAlt || 0)}°</td>
                </tr>
                <tr>
                  <td className="akkura xsmall caps">Max</td>
                  <td className="akkura small">
                    {showUTCTime
                      ? pass?.peakTime.toUTC().toFormat('h:mm:ss a')
                      : pass?.peakTime.toFormat('h:mm:ss a')}
                  </td>
                  <td className="akkura small">
                    {degToCardinal(pass?.peakAzimuth || 0)}
                  </td>
                  <td className="akkura small">{Math.floor(pass?.peakAlt || 0)}°</td>
                </tr>
                <tr>
                  <td className="akkura xsmall caps">End</td>
                  <td className="akkura small">
                    {showUTCTime
                      ? pass?.setTime.toUTC().toFormat('h:mm:ss a')
                      : pass?.setTime.toFormat('h:mm:ss a')}
                  </td>
                  <td className="akkura small">
                    {degToCardinal(pass?.setAzimuth || 0)}
                  </td>
                  <td className="akkura small">{Math.floor(pass?.setAlt || 0)}°</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StationSightingModal;
