import React, { FC } from 'react';
import { DateTime } from 'luxon';
import * as styles from './station-selector.module.scss';

const timezones = [
  {
    title: DateTime.local().offsetNameShort,
    utc: false
  },
  {
    title: 'UTC',
    utc: true
  }
];

const StationTimezoneSelector: FC<{
  showUTCTime: boolean;
  onSetShowUTCTime: (boolean: any) => void;
}> = ({ showUTCTime, onSetShowUTCTime }) => {
  return (
    <ul className={styles.station_time}>
      {timezones.map((timezone) => (
        <li
          key={timezone.title}
          className="bau"
          data-selected={timezone.utc === showUTCTime}
          onClick={() => onSetShowUTCTime(timezone.utc)}
        >
          {timezone.title}
        </li>
      ))}
    </ul>
  );
};

export default StationTimezoneSelector;
