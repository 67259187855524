import React, { useEffect, useState, useRef, FC, useCallback, useMemo } from 'react';
import cx from 'classnames';
import { useWindowSize } from '../../../state/use-window-size';
import ImageBackgroundSanity from '../../core/image-background-sanity';
import {
  deriveStationCraftImageUrl,
  deriveStationPortLayouts,
  deriveStationScaledRect,
  SanityImageRotation,
  SanityImage,
  Size,
  Station,
  Point,
  StationPortLayout
} from 'superclient';
import StationCraftPreview from '../station-craft-preview/station-craft-preview';
import * as styles from './station-traffic-view.module.scss';
import { Superclient } from '../../../clients/superclient';

const usePrevious = <T extends unknown>(value: T) => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

const dotMargin = 20;
const dotWidth = 12;

const StationTrafficView: FC<{
  station: Station;
  showTopView: boolean;
  highlightedPort?: string;
  selectedPort?: string;
  onPortHighlight: (value?: string) => void;
  onSelectedPort: (value?: string) => void;
}> = ({
  station,
  showTopView,
  highlightedPort,
  onPortHighlight,
  selectedPort,
  onSelectedPort
}) => {
  const craftImageRefs = useRef<{ [url: string]: HTMLImageElement }>({});
  const boundryCanvasRef = useRef<HTMLCanvasElement>(null);
  const portClicked = useRef(false);
  const [layoutReady, setLayoutReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const windowSize = useWindowSize();
  const isMobile = (windowSize.width || 1500) <= 1200;
  const [stationLayoutDimensions, setStationLayoutDimensions] = useState<Size>(
    showTopView
      ? station.topImage.asset.metadata.dimensions
      : station.sideImage.asset.metadata.dimensions
  );
  const previousShowTopView = usePrevious(showTopView);
  const previousStationId = usePrevious(station._id);
  const switchingViews = showTopView !== previousShowTopView;

  useEffect(() => {
    let mounted = true;
    setLayoutReady(false);

    setTimeout(() => {
      if (mounted) {
        setLayoutReady(true);
      }
    }, 750);

    return () => {
      mounted = false;
    };
  }, [showTopView, station._id]);

  const stationImageDimensions = useMemo(() => {
    return showTopView
      ? station.topImage.asset.metadata.dimensions
      : station.sideImage.asset.metadata.dimensions;
  }, [station, showTopView]);

  const { portLayouts, boundaryImagesToLoad } = useMemo(
    () =>
      Superclient.deriveStationPortLayouts(
        station,
        showTopView,
        dotMargin,
        stationImageDimensions,
        stationLayoutDimensions,
        isMobile ? 4 : 1.5
      ),
    [
      station,
      showTopView,
      dotMargin,
      stationImageDimensions,
      stationLayoutDimensions,
      isMobile
    ]
  );

  const loadCanvasImage = useCallback(
    (url, onComplete) => {
      if (!craftImageRefs.current[url]) {
        const image = new Image();
        image.crossOrigin = 'Anonymous';
        image.onload = () => {
          craftImageRefs.current[url] = image;

          let missingImages = false;
          boundaryImagesToLoad.forEach((imageUrl) => {
            if (!craftImageRefs.current[imageUrl]) {
              missingImages = true;
            }
          });

          onComplete(missingImages);
        };
        image.src = url;
      }

      return url;
    },
    [boundaryImagesToLoad]
  );

  const canvasImage = (
    sanityImage: SanityImage,
    rotation: SanityImageRotation,
    imageCallback: (image: HTMLImageElement) => void
  ) => {
    const url = Superclient.deriveStationCraftImageUrl(sanityImage, rotation);

    if (url && craftImageRefs.current[url]) {
      if (imageCallback) {
        imageCallback(craftImageRefs.current[url]);
      }
    }

    return url;
  };

  useEffect(() => {
    let mounted = true;

    let currentlyMissingImages = false;
    boundaryImagesToLoad.forEach((imageUrl) => {
      if (!craftImageRefs.current[imageUrl]) {
        currentlyMissingImages = true;
      }
    });
    setLoading(currentlyMissingImages);

    boundaryImagesToLoad.forEach((imageUrl) => {
      if (!craftImageRefs.current[imageUrl]) {
        loadCanvasImage(imageUrl, (missingImages) => {
          if (mounted) {
            setLoading(missingImages);
          }
        });
      }
    });

    return () => {
      mounted = false;
    };
  }, [boundaryImagesToLoad]);

  useEffect(() => {
    setTimeout(() => {
      getStationDimensions();
    }, 500);
  }, []);

  useEffect(() => {
    getStationDimensions();
  }, [windowSize, showTopView, loading, station._id]);

  useEffect(() => {
    let mounted = true;
    if (loading || !boundryCanvasRef.current || !portLayouts) {
      return;
    }

    const boundryContext = boundryCanvasRef.current.getContext('2d');
    boundryContext!.clearRect(
      0,
      0,
      stationImageDimensions.width,
      stationImageDimensions.height
    );

    portLayouts.forEach((port, index) => {
      if (!port.craft || !port.craftImageBitmap || !port.craftImageHoverBoundary) {
        return;
      }

      canvasImage(port.craftImageHoverBoundary, port.craftRotation || 0, (image) => {
        if (mounted && port.craftImageRect) {
          boundryContext!.drawImage(
            image,
            port.craftImageRect.x,
            port.craftImageRect.y,
            port.craftImageRect.width,
            port.craftImageRect.height
          );
        }
      });
    });

    return () => {
      mounted = false;
    };
  }, [portLayouts, showTopView, loading, stationImageDimensions]);

  const getStationDimensions = () => {
    const element = document.getElementById('iss_traffic_view__iss');
    if (element) {
      const height = element.clientHeight;
      const width = element.clientWidth;
      setStationLayoutDimensions({ width, height });
    }
  };

  const distance = (port: StationPortLayout, cursorPosition: Point) => {
    if (!port.craft || !port.craftRect) {
      return 100000;
    }
    const craftCenter = {
      x: port.craftRect.x + port.craftRect.width / 2,
      y: port.craftRect.y + port.craftRect.height / 2
    };
    const x = cursorPosition.x - craftCenter.x;
    const y = cursorPosition.y - craftCenter.y;
    return Math.sqrt(x * x + y * y);
  };

  const getCurrentCursorState = useCallback(
    (e) => {
      if (
        loading ||
        !boundryCanvasRef.current ||
        !portLayouts ||
        stationLayoutDimensions.width === 0 ||
        stationLayoutDimensions.height === 0
      ) {
        return { closestPort: 0 };
      }

      const issElement = document.getElementById('iss_traffic_view__iss');
      const issBounds = issElement?.getBoundingClientRect();
      const position: Point = {
        x: e.clientX - (issBounds?.x || 0),
        y: e.clientY - (issBounds?.y || 0)
      };

      const adjustedPosition: Point = {
        x:
          (position.x * stationImageDimensions.width) /
          stationLayoutDimensions.width,
        y:
          (position.y * stationImageDimensions.height) /
          stationLayoutDimensions.height
      };

      const context = boundryCanvasRef.current.getContext('2d');
      const rawData = context!.getImageData(
        adjustedPosition.x,
        adjustedPosition.y,
        1,
        1
      );

      if (!rawData || !rawData.data) {
        return { closestPort: 0 };
      }
      const p = rawData.data;
      const brightness = (p[0] + p[1] + p[2]) / 3;
      const inBounds = brightness > 0;
      let closestPort;

      if (inBounds) {
        const sortedPorts = [...portLayouts].sort(
          (a, b) => distance(a, adjustedPosition) - distance(b, adjustedPosition)
        );

        closestPort = sortedPorts[0]._key;
      }

      return { closestPort };
    },
    [stationImageDimensions, stationLayoutDimensions]
  );

  const renderPorts = useMemo(
    () =>
      !loading &&
      stationLayoutDimensions.width > 0 &&
      stationLayoutDimensions.height > 0,
    [stationLayoutDimensions]
  );

  const onClick = (e) => {
    const state = getCurrentCursorState(e);
    const { closestPort } = state;

    if (renderPorts) {
      if (portClicked.current) {
        portClicked.current = false;
        return;
      }
      if (selectedPort === closestPort) {
        onSelectedPort(undefined);
      } else {
        onSelectedPort(closestPort);
      }
    }
  };

  const stationType = useMemo(() => {
    let className = '';

    if (station.slug.current === 'iss') {
      className = showTopView ? 'iss-top' : 'iss-side';
    } else {
      className = showTopView ? 'generic-top' : 'generic-side';
    }

    return className;
  }, [station, showTopView]);

  return (
    <div id="iss_traffic_view__container" className={styles.iss_traffic_view}>
      <div
        id="iss_traffic_container"
        className={cx(`${styles.iss_traffic_view__content} rel x y f aic jcc`, {
          pointer: highlightedPort !== undefined || selectedPort !== undefined,
          [styles.iss_traffic_view__content__fade_in]: layoutReady && !switchingViews
        })}
        onClick={onClick}
      >
        <div
          id="iss_traffic_view__iss"
          className={styles.iss_traffic_view__station_container}
          data-type={stationType}
        >
          <img
            src={
              showTopView ? station.topImage.asset.url : station.sideImage.asset.url
            }
          />
          {!loading && (
            <>
              <canvas
                ref={boundryCanvasRef}
                className="abs top left x y op0"
                width={stationImageDimensions.width}
                height={stationImageDimensions.height}
                onMouseMove={(e) => {
                  const { closestPort } = getCurrentCursorState(e);
                  if (selectedPort === undefined) {
                    onPortHighlight(closestPort);
                  }
                }}
              />

              {portLayouts.map((port, index) => {
                if (!port.craft || !port.craftImageRect || !port.craftImageBitmap)
                  return null;

                const scaledRect = deriveStationScaledRect(
                  port.craftImageRect,
                  stationImageDimensions,
                  stationLayoutDimensions
                );

                return (
                  <div
                    key={`craft-${index}`}
                    className={`${styles.iss_traffic_view__craft} f aic jcc`}
                    style={{
                      left: scaledRect.x + 'px',
                      top: scaledRect.y + 'px',
                      width: scaledRect.width + 'px',
                      height: scaledRect.height + 'px'
                    }}
                  >
                    {port.craftImageSilhouette && (
                      <ImageBackgroundSanity
                        className={
                          (highlightedPort !== undefined &&
                            highlightedPort !== port._key) ||
                          (selectedPort !== undefined && selectedPort !== port._key)
                            ? 'op1'
                            : 'op0'
                        }
                        fadeIn={false}
                        image={port.craftImageSilhouette}
                        alt={port.craftUniqueName}
                        orientation={port.craftRotation}
                        lazyLoad
                      />
                    )}
                    {port.craftImageSilhouetteColored && (
                      <ImageBackgroundSanity
                        className={
                          highlightedPort === undefined && selectedPort === undefined
                            ? 'op1'
                            : 'op0'
                        }
                        image={port.craftImageSilhouetteColored}
                        alt={port.craftUniqueName}
                        orientation={port.craftRotation}
                        lazyLoad
                      />
                    )}
                    {port.craftImageBitmap && (
                      <ImageBackgroundSanity
                        className={
                          (highlightedPort !== undefined
                          ? highlightedPort === port._key
                          : selectedPort === port._key)
                            ? 'op1'
                            : 'op0'
                        }
                        image={port.craftImageBitmap}
                        fadeIn={false}
                        alt={port.craftUniqueName}
                        orientation={port.craftRotation}
                        lazyLoad
                      />
                    )}
                  </div>
                );
              })}

              {renderPorts &&
                portLayouts.map((port, index) =>
                  port.dotPosition ? (
                    <div
                      key={`craft-port-${index}`}
                      className={styles.iss_traffic_view__craft_dot__container}
                      style={{
                        padding: dotMargin + 'px',
                        left: port.dotLabelIsPrefixed
                          ? undefined
                          : port.dotPosition.x - dotWidth / 2 - dotMargin + 'px',
                        right: port.dotLabelIsPrefixed
                          ? stationLayoutDimensions.width -
                            port.dotPosition.x -
                            dotWidth / 2 -
                            dotMargin +
                            'px'
                          : undefined,
                        top: port.dotPosition.y - dotWidth * 0.5 - dotMargin + 'px',
                        opacity:
                          (highlightedPort === undefined &&
                            selectedPort === undefined) ||
                          (highlightedPort !== undefined
                            ? highlightedPort === port._key
                            : selectedPort === port._key)
                            ? 1
                            : 0.3
                      }}
                      onTouchStart={() => onPortHighlight(port._key)}
                      onMouseEnter={() => {
                        onPortHighlight(port._key);
                      }}
                      onMouseLeave={() => onPortHighlight()}
                      onClick={
                        port.craft
                          ? () => {
                              if (port._key === selectedPort) {
                                onSelectedPort();
                              } else {
                                onSelectedPort(port._key);
                              }
                              portClicked.current = true;
                            }
                          : undefined
                      }
                      onTouchEnd={() => onPortHighlight()}
                      onTouchCancel={() => onPortHighlight()}
                    >
                      {port.dotLabelIsPrefixed && (
                        <div
                          className="mr1 akkura caps xsmall cw"
                          style={{
                            top: '1px',
                            lineHeight: dotWidth + 'px'
                          }}
                        >
                          {port.portNumber}
                        </div>
                      )}
                      <div
                        className={
                          port.craft
                            ? styles.iss_traffic_view__craft_dot
                            : styles.iss_traffic_view__craft_dot_empty
                        }
                        style={{
                          width: dotWidth + 'px',
                          height: dotWidth + 'px',
                          backgroundColor: port.craft ? port.portGroupColor.hex : '',
                          border: port.craft
                            ? ''
                            : `1px solid ${port.portGroupColor.hex}`,
                          borderRadius: '50%'
                        }}
                      />
                      {!port.dotLabelIsPrefixed && (
                        <div
                          className="ml1 akkura caps xsmall cw"
                          style={{
                            top: '1px',
                            lineHeight: dotWidth + 'px'
                          }}
                        >
                          {port.portNumber}
                        </div>
                      )}
                    </div>
                  ) : null
                )}
              {portLayouts.map((port) => {
                if (!port.craftPreviewRect || selectedPort !== port._key)
                  return null;

                const scaledRect = deriveStationScaledRect(
                  port.craftPreviewRect,
                  stationImageDimensions,
                  stationLayoutDimensions
                );

                return (
                  <div
                    key={`craft-preview-${port._key}`}
                    className="abs z10 show--m"
                    style={{
                      left: scaledRect.x + 'px',
                      top: scaledRect.y + 'px',
                      width: scaledRect.width + 'px',
                      height: scaledRect.height + 'px'
                    }}
                  >
                    <div className="rel x y">
                      <StationCraftPreview
                        isMobile={false}
                        portName={port.portName}
                        portNumber={port.portNumber}
                        craft={port.craft}
                        craftUniqueName={port.craftUniqueName}
                        craftArrivalDate={port.craftArrivalDate}
                        craftOverrideArrivalDate={port.craftOverrideArrivalDate}
                        craftDepartureDate={port.craftDepartureDate}
                        craftOverrideDepartureDate={port.craftOverrideDepartureDate}
                        onClose={() => {
                          onSelectedPort();
                          onPortHighlight();
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default StationTrafficView;
