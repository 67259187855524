import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import {
  Station,
  StationPass,
  relativeDayDescription,
  StationPassType,
  LocationResult
} from 'superclient';
import * as styles from './station-sightings.module.scss';
import useCookie from 'react-use-cookie';
import { Superclient } from '../../../clients/superclient';

const StationSightings: FC<{
  isMobile: boolean;
  station: Station;
  showUTCTime: boolean;
  onSelectPass: (pass: StationPass) => void;
}> = ({ isMobile, station, showUTCTime, onSelectPass }) => {
  const [locationDataJSON, setLocationDataJSON] = useCookie(
    'location-data',
    JSON.stringify({
      name: 'New York',
      latitude: 40.7128,
      longitude: -74.006
    })
  );
  const [typeFilter, seTypeFilter] = useCookie(
    'sightings-filter',
    StationPassType.Best
  );
  const [passes, setPasses] = useState<StationPass[] | undefined>(undefined);
  const [loadingPasses, setLoadingPasses] = useState(false);
  const locationData = useMemo(() => {
    if (!locationDataJSON) {
      return undefined;
    }
    return JSON.parse(locationDataJSON);
  }, [locationDataJSON]);
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [results, setResults] = useState<LocationResult[]>();
  const [isFieldFocused, setIsFieldFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const [search, setSearch] = useState<string | undefined>(locationData.name || '');

  useEffect(() => {
    setPasses(undefined);
  }, [locationData, station._id]);

  useEffect(() => {
    if (!locationData) return;
    setSearch(locationData.name);
  }, [locationData]);

  useEffect(() => {
    if (search !== locationData.name || '') {
      Superclient.performLocationSearch(search).then((r) => {
        setResults(r);
      });
    }
  }, [search]);

  const filteredPasses: StationPass[] = useMemo(() => {
    return passes?.filter((pass) => {
      if (typeFilter === 'all') {
        return true;
      } else if (typeFilter === 'visible') {
        return pass.visible;
      } else if (typeFilter === 'best') {
        return pass.rating > 65 && pass.visible;
      }
      return true;
    });
  }, [station, passes, typeFilter]);

  useEffect(() => {
    if (!locationData) return;

    setLoadingPasses(true);
    Superclient.getPasses(station, {
      latitude: locationData.latitude,
      longitude: locationData.longitude
    }).then((passes) => {
      setPasses(passes);
      setLoadingPasses(false);
    });
  }, [locationData, station]);

  const selectResult = useCallback((result: LocationResult) => {
    setLocationDataJSON(
      JSON.stringify({
        name: result.name,
        latitude: result.latitude,
        longitude: result.longitude
      })
    );
    setIsFieldFocused(false);
  }, []);

  const useCurrentLocation = useCallback(() => {
    setIsFieldFocused(false);
    setLoadingLocation(true);
    if (navigator.geolocation) {
      setPasses(undefined);
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          console.log('Latitude: ' + position.coords.latitude);
          console.log('Longitude: ' + position.coords.longitude);

          const geocodeResult = await Superclient.reverseGeocode(
            position.coords.latitude,
            position.coords.longitude
          );

          let locationName = 'Unknown Location';
          if (geocodeResult && geocodeResult.length > 0) {
            locationName = geocodeResult[0].name;
          }

          setLocationDataJSON(
            JSON.stringify({
              name: locationName,
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            })
          );
          setLoadingLocation(false);
        },
        (error) => {
          console.error('Error Code = ' + error.code + ' - ' + error.message);
          setLoadingLocation(false);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      setLoadingLocation(false);
    }
  }, []);

  return (
    <div
      className={classnames(`${styles.station_sightings} f1 x f fc`, {
        pb1: !isMobile
      })}
    >
      {!isMobile && <h6 className="caps pb0 mb15 mt0">Sightings</h6>}
      <div className="f1 rel">
        <ul
          className={classnames('abs bau small y x ovfys dark-scrollbar pr1', {
            py15: isMobile
          })}
        >
          <div className={`${styles.station_sightings__search_container}`}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10.5 18.99V14.73H9.5V18.99C4.93 18.74 1.27 15.07 1.01 10.5H5.27V9.5H1.01C1.27 4.93 4.93 1.27 9.5 1.01V5.27H10.5V1.01C15.07 1.26 18.73 4.93 18.99 9.5H14.73V10.5H18.99C18.74 15.07 15.07 18.74 10.5 18.99Z"
                fill="white"
              />
            </svg>

            <input
              value={loadingLocation ? 'Loading...' : search}
              ref={inputRef}
              className="akkura caps "
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Enter a location"
              onFocus={() => setIsFieldFocused(true)}
            />
            {isFieldFocused && search.length > 0 && (
              <button
                className="clickable"
                onClick={() => {
                  setSearch('');
                  inputRef.current.focus();
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="10" cy="10" r="9.5" stroke="white" />
                  <path
                    d="M14.2426 14.2426L5.75736 5.75736M5.75736 14.2426L14.2426 5.75736"
                    stroke="white"
                  />
                </svg>
              </button>
            )}
          </div>
          <div
            className={styles.station_sightings__search_results}
            data-visible={isFieldFocused}
          >
            <div className="dark-scrollbar">
              <button className="akkura caps clickable" onClick={useCurrentLocation}>
                Use Current Location
              </button>
              {results?.map((result, index) => (
                <div
                  key={result.name}
                  className="akkura caps clickable"
                  style={{
                    borderBottom:
                      index < results.length - 1 ? '1px solid #fff' : 'none'
                  }}
                  onClick={() => selectResult(result)}
                >
                  {result.name}
                </div>
              ))}
            </div>
          </div>

          <div className={`${styles.station_sightings__filter}`}>
            {[
              StationPassType.Best,
              StationPassType.Visible,
              StationPassType.All
            ].map((type, index) => (
              <div
                key={type}
                data-selected={typeFilter === type}
                onClick={() => seTypeFilter(type)}
                className="akkura caps"
                style={{
                  borderLeft: index > 0 ? '1px solid #fff' : 'none'
                }}
              >
                {type}
              </div>
            ))}
          </div>
          <div className="x bcw height-1" />
          {(!filteredPasses || filteredPasses.length === 0) && (
            <div className={`${styles.station_sightings__empty} white`}>
              {loadingPasses
                ? 'Loading...'
                : `There are no ${
                    typeFilter === 'best'
                      ? 'good visible'
                      : typeFilter === 'visible'
                      ? 'visible'
                      : 'passes'
                  } in your area for the next few weeks. Check back in a bit!`}
            </div>
          )}
          {filteredPasses?.map((pass) => (
            <React.Fragment key={pass.riseTime.toISO()}>
              <li
                className={`${styles.station_sightings__cell} x f fr clickable`}
                onClick={() => onSelectPass(pass)}
              >
                <div className="f1">
                  <div className="akkura small caps">
                    {showUTCTime
                      ? relativeDayDescription(pass.riseTime.toUTC())
                      : relativeDayDescription(pass.riseTime)}
                  </div>
                  <div className="akkura small caps">
                    {showUTCTime
                      ? pass.riseTime.toUTC().toFormat('hh:mm a')
                      : pass.riseTime.toFormat('hh:mm a')}
                  </div>
                </div>
                <div className="f1">
                  <div className="akkura small caps">
                    {`${Math.floor(pass.duration / 60)} MIN${
                      Math.floor(pass.duration / 60) === 1 ? '' : 'S'
                    }`}
                  </div>
                  <div className="f fr">
                    {pass.visible ? (
                      <>
                        {[
                          ...Array(Math.max(Math.ceil((pass.rating / 100) * 5), 1))
                        ].map((_, i) => (
                          <div
                            key={`star-${i}`}
                            style={{
                              width: 10,
                              height: 10,
                              marginRight: 3,
                              borderRadius: 5,
                              backgroundColor: 'var(--green)'
                            }}
                          />
                        ))}
                      </>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height={16}
                        viewBox="0 0 24 19"
                        fill="none"
                      >
                        <path
                          fill="#fff"
                          fillRule="evenodd"
                          d="M14.064 2.22A10.01 10.01 0 0 0 12 2C5.373 2 0 9 0 9s2.807 3.658 6.867 5.685l.5-.867c-1.183-.585-2.273-1.33-3.225-2.097A23.486 23.486 0 0 1 1.303 9a23.477 23.477 0 0 1 2.84-2.721C6.246 4.582 9.024 3 12 3c.518 0 1.03.048 1.534.136l.53-.916ZM10.25 14.824A8.973 8.973 0 0 0 12 15c2.975 0 5.753-1.582 7.858-3.279A23.484 23.484 0 0 0 22.697 9a23.475 23.475 0 0 0-2.84-2.721c-1-.808-2.155-1.59-3.41-2.186l.501-.87C21.107 5.232 24 9 24 9s-5.373 7-12 7c-.778 0-1.538-.096-2.274-.267l.525-.91Z"
                          clipRule="evenodd"
                        />
                        <path
                          fill="#fff"
                          fillRule="evenodd"
                          d="M11.88 6.002a3 3 0 0 0-2.536 4.393l2.536-4.393Zm.002 5.996L12 12a3 3 0 0 0 2.538-4.601l-2.655 4.599Z"
                          clipRule="evenodd"
                        />
                        <path
                          fill="#fff"
                          d="m16.333.28.87.502L7.17 18.16l-.87-.502z"
                        />
                      </svg>
                    )}
                  </div>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={30}
                  height={30}
                  fill="none"
                >
                  <path stroke="#fff" d="m12 21 8-6-8-6" />
                  <circle cx={15} cy={15} r={14.5} stroke="#fff" />
                </svg>
              </li>
              <div className="x bcw height-1" />
            </React.Fragment>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default StationSightings;
