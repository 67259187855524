import React, { FC, useCallback } from 'react';
import cx from 'classnames';
import { StationPort, StationPortGroup } from 'superclient';
import * as styles from './station-port-list.module.scss';
import { DateTime } from 'luxon';

const ISSPortList: FC<{
  isMobile: boolean;
  portGroups: StationPortGroup[];
  highlightedPort: string | undefined;
  selectedPort: string | undefined;
  onPortHighlight: (key?: string) => void;
  onSelectedPort: (key?: string) => void;
}> = ({
  isMobile,
  portGroups,
  highlightedPort,
  onPortHighlight,
  selectedPort,
  onSelectedPort
}) => {
  const focusedPort: string | undefined =
    highlightedPort === undefined && selectedPort === undefined
      ? undefined
      : highlightedPort !== undefined
      ? highlightedPort
      : selectedPort;

  const groupIsBright = useCallback(
    (group: StationPortGroup) => {
      if (!focusedPort) return true;
      return group.ports.some((p) => p._key === focusedPort);
    },
    [focusedPort]
  );

  const portIsBright = (key: string) =>
    (highlightedPort === undefined && selectedPort === undefined) ||
    (highlightedPort !== undefined ? highlightedPort === key : selectedPort === key);

  const renderPort = (port: StationPort, group: StationPortGroup) => {
    const arrivalDate = port.craft
      ? port.overrideArrivalDate ||
        (port.arrivalDate
          ? DateTime.fromISO(port.arrivalDate.utc || '').toFormat('MMM d y')
          : 'TBD')
      : '';
    const departureDate = port.craft
      ? port.overrideDepartureDate ||
        (port.departureDate
          ? DateTime.fromISO(port.departureDate.utc || '').toFormat('DD')
          : 'TBD')
      : '';
    return (
      <div
        key={'iss-port-' + port._key}
        className="f fr pb1 pointer"
        onTouchStart={() => onPortHighlight(port._key)}
        onMouseEnter={() => onPortHighlight(port._key)}
        onClick={port.craft ? () => onSelectedPort(port._key) : undefined}
        onMouseLeave={() => onPortHighlight()}
        onTouchEnd={() => onPortHighlight()}
        onTouchCancel={() => onPortHighlight()}
        style={{
          opacity: portIsBright(port._key) ? 1 : 0.3
        }}
      >
        <div
          className={`mt025 mr15 ${styles.station_port_dot}`}
          data-filled={port.craft !== undefined}
          style={
            port.craft
              ? { backgroundColor: group.color.hex }
              : { border: `1px solid ${group.color.hex}` }
          }
        />
        <div className={isMobile ? '' : 'mr15'}>
          {`${port.portNumber} - ${port.craft ? port.craftUniqueName : ''}`}
          {!isMobile && port.craft && (
            <>
              <br />
              {`${arrivalDate} - ${departureDate}`}
            </>
          )}
          {!isMobile &&
            (highlightedPort !== undefined
              ? highlightedPort === port._key
              : selectedPort === port._key) && (
              <>
                <br />
                {port.portName}
              </>
            )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={cx('x f fc pb15', {
        pt15: isMobile,
        f1: isMobile,
        f15: !isMobile
      })}
    >
      {!isMobile && <h6 className="caps pb0 mb15 mt0">Ports</h6>}
      <div className="f1 rel akkura small caps" style={{ minHeight: '40vh' }}>
        {!isMobile ? (
          <div className="x y abs ovfys dark-scrollbar">
            {portGroups.map((group) => (
              <div key={`nation-${group.name}`}>
                <div className="f fr aic mb075 small">
                  <div
                    className={`mr15`}
                    style={{ opacity: groupIsBright(group) ? 1 : 0.3 }}
                  >
                    {group.name}
                  </div>
                  <div className="f1 height-1 bcw" />
                </div>
                {group.ports.map((p) => {
                  return renderPort(p, group);
                })}
              </div>
            ))}
          </div>
        ) : (
          <div className="x y abs">
            <div className="f fr y">
              {portGroups.map((group, index) => (
                <div
                  className={`f fc f1 y ${
                    index !== portGroups.length - 1 ? 'mr1' : ''
                  }`}
                  key={`m-nation-${group._key}`}
                >
                  <div className="x f fr aic mb1">
                    <div
                      className={`akkura small caps mr1`}
                      style={{
                        opacity: groupIsBright(group) ? 1 : 0.3
                      }}
                    >
                      {group.name}
                    </div>
                  </div>
                  <div className="f1 ovfys dark-scrollbar mb1">
                    {group.ports.map((p) => {
                      return renderPort(p, group);
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ISSPortList;
