import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { Astronaut } from 'superclient';
import ImageSanity from '../../core/image-sanity';
import ImageBackgroundSanity from '../../core/image-background-sanity';
import AstronautCard from '../../astronauts/astronaut-card';
import { ListTypes } from '../../core/super-card';
import * as styles from './station-crew.module.scss';

const StationCrew: FC<{ isMobile: boolean; crew: Astronaut[] }> = ({
  isMobile,
  crew
}) => {
  const [highlightedAstronaut, setHighlightedAstronaut] = useState<Astronaut>();

  return (
    <div className="f1 x f fc">
      {!isMobile && <h6 className="caps pb0 mb1 mt1">Crew</h6>}
      <div className="f1 rel">
        <div
          className={classNames(
            `${styles.station_crew__container} dark-scrollbar f`,
            {
              py15: isMobile,
              fr: !isMobile,
              fc: isMobile,
              fw: !isMobile
            }
          )}
          data-is-mobile={isMobile}
        >
          {crew.map((astronaut) =>
            !isMobile ? (
              <div key={astronaut._id} className="rel">
                <Link to={`/astronauts/${astronaut.slug.current}`}>
                  <div
                    onMouseEnter={() => setHighlightedAstronaut(astronaut)}
                    onMouseLeave={() => setHighlightedAstronaut(undefined)}
                    className={styles.station_crew__desktop_profile}
                  >
                    <ImageBackgroundSanity
                      image={astronaut.image}
                      alt={astronaut.name}
                      width={75}
                      height={75}
                      lazyLoad
                    />
                  </div>
                </Link>
                {highlightedAstronaut && highlightedAstronaut._id === astronaut._id && (
                  <div className="abs z10" style={{ left: 70, top: -190 }}>
                    <AstronautCard
                      astronaut={highlightedAstronaut}
                      paramString={''}
                      listType={ListTypes.CardListX}
                    />
                  </div>
                )}
              </div>
            ) : (
              <Link
                key={astronaut._id}
                className="rel"
                to={`/astronauts/${astronaut.slug.current}`}
              >
                <div
                  className={styles.station_crew__mobile_profile}
                  onMouseEnter={() => setHighlightedAstronaut(astronaut)}
                  onMouseLeave={() => setHighlightedAstronaut(undefined)}
                >
                  <div className={styles.station_crew__mobile_image}>
                    <ImageBackgroundSanity
                      image={astronaut.image}
                      alt={astronaut.name}
                      width={75}
                      height={75}
                      lazyLoad
                    />
                  </div>
                  <div className="f1 ml2 bau-bold h4 cw al my05 f fr aic ">
                    <div className="f1 f fc">
                      <div className="f fr">
                        <div className="f1">{astronaut.name}</div>
                        <div className="bcbl mr05">
                          <div className="akkura caps cw xsmall ac mb05 mt075 mx1">
                            {astronaut.astroNumber}
                          </div>
                        </div>
                      </div>
                      <div className="f fr mt025">
                        {astronaut.nations.map((nation) => (
                          <div
                            key={nation._id}
                            className="rel mb1 f fr jcc mr1"
                            style={{
                              width:
                                15 *
                                nation.flag.asset.metadata.dimensions.aspectRatio,
                              height: 15
                            }}
                          >
                            <ImageSanity
                              className="abs x y top left"
                              image={nation.flag}
                              alt={nation.name}
                              height={15}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            )
          )}
          {isMobile && <div className="m1 op0">space :)</div>}
        </div>
      </div>
      {!isMobile && <div className="akkura small caps mt2">Not to Scale</div>}
    </div>
  );
};

export default StationCrew;
