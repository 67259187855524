import React, { FC } from 'react';

const CloseButton: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path d="M0.400002 0.4L13.1 13.1" stroke="black" />
        <path d="M13.1 0.4L0.400002 13.1" stroke="black" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="13.5" height="13.5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseButton;
